<template>
    <section class="anuncios-mis-anuncios">
        <!-- Titulo + opciones -->
        <div class="row mx-0 mb-4">
            <div class="col-12 col-lg-auto">
                <p class="f-20 f-500">Anuncios de administración</p>
            </div>
            <div class="col-auto mx-2 ml-auto">
                <div class="row bg-f5 border br-8 indicadores">
                    <div class="col-auto my-auto">
                        <p class="f-17 f-500"> 
                            {{ total }}
                            <span class="f-100 pl-2 f-13 text-muted">Total</span>
                        </p>
                    </div>
                    <div class="col pr-0">
                        <div class="indicador1 d-middle-center">
                            <i class="icon-ad op-05" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto px-0">
                <button class="btn btn-general h-32px f-300 f-14" @click="nuevoAnuncio">
                    Nuevo anuncio
                </button>
            </div>
            <div class="col-auto">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @change="listarCarteleras" />
            </div>
        </div>
        <div class="row mx-0 overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
            <div v-for="(cartelera,i) in carteleras" :key="i" class="col-auto px-1">
                <anuncio :cartelera="cartelera" />
            </div>
            <div class="col-12">
                <infinite-loading @infinite="listarCarteleras">
                    <div slot="spinner">
                        Consultando...
                    </div>
                    <div slot="no-more">
                        No hay más anuncios
                    </div>
                    <div slot="no-results" />
                </infinite-loading>
            </div>
        </div>        
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

import Anuncio from '@/components/card/anuncio.vue'
import Carteleras from '~/services/carteleras'
export default {
    components:{
        Anuncio,
        InfiniteLoading
    },
    data(){
        return{
            search:'',
            carteleras:[],
            total: 0,
            page: 1
        }
    },
    watch: {
        search(){
            this.page = 1
            this.carteleras = []
        }
    },
    methods:{
        nuevoAnuncio(){
            this.$router.push({ name:'anuncios.crear' })
        },
        verMiAnuncio(id){
            this.$router.push({ name:'anuncios.mis.anuncios.ver', params:{id_cartelera:id} })
        },
        async listarCarteleras($state){
            try {
                const state = $state
                this.loading = true
                let params={
                    page: this.page,
                    search : this.search,
                }
                const {data} = await Carteleras.listarPropias(params)
                this.total = data.data.total
                if(data.data.data.length){
                    this.page += 1;
                    this.carteleras.push(...data.data.data)
                    this.loading = false
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                    this.loading = false

                }
            } catch (e){
                this.errorCatch(e)
                this.loading = false
            }  
        }
    }
}
</script>
<style lang="scss" scoped>
.anuncios-mis-anuncios{
    height: calc(100vh - 158px);
}
.card-solicitud{
        max-width: 304px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        position: relative;
        .seleccionado{
            width: 8px;
            background: var(--color-general);
            position: absolute;
            height: 98%;
            border-radius: 20px;
            top: 0px;
            left: -11px;
            z-index: 1;
        }
        .container-img{
            height: 182px;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .container-info{
            // min-height: 141px;
            // height: 141px;
            border-radius: 8px;
            border:1px solid #DBDBDB;
            position: relative;
            top: -8px;
            background: white;
            .categoria{
                min-height: 22px;
            }
            .tipo-user{
                min-width: 32px;
                max-width: 32px;
                max-height: 32px;
                min-height: 32px;
            }
        }
    }
</style>